<template>
  <div>
    <div class="title">购买须知</div>
    <div style="text-align: center; line-height: 20px; margin-top: 10px;">
      发布时间：2022-04-11 20:19:26
    </div>
    <div data-v-0ab10f90="" style="font-size: 14px;"><p>&nbsp;</p>
      <p><strong> <span>深圳市合囤供应链有限责任公司的商品都是正品吗？</span> </strong></p>
      <p style="text-indent: 2em;line-height: 27px;">
        <span>平台上所有产品都是供应商厂家直发，经过公司严格筛选把关后对外销售，所有产品都有相关链路资料和发货物流溯源，如确定我们销售产品并非正品我们将给予退货和产品的全额赔偿。（前提一定是我们出售的货品）图片可能会因为灯光或者显示器的关系有所差异，期望得到各买家的理解！</span>
      </p>
      <p><strong> <span>快递问题</span> </strong></p>
      <p style="text-indent: 2em;line-height: 27px;">
        <span>快递一般是2-3天到达,但因所在城市不同，运送速度会有一定的不一,特别是比较偏远的地区。如果是遇到恶劣天气以及法定节假日，都会有延迟耽搁现象。另外快递公司每个网点的业务员素质也不一，中间可能出现个别网点业务员态度不好或者包裹损坏等，一些卖家无法预知的意外因素。总之有什么不愉快请联系我们，我们会积极跟快递公司沟通解决的。</span>
      </p>
      <p><strong> <span>质量问题</span> </strong></p>
      <p style="text-indent: 2em;line-height: 27px;">
        <span>所有宝贝发货前都会有专门发货部门进行严格质检，保证到您手上的宝贝的质量。为了避免运输中的可能造成的不可抗拒因素，请您收到宝贝之后当这快递员的面打开检查，确认无误后再签收快递单子。货物一旦签收完毕，就代表双方对货物外表、数量、配件一致认可无争议。如宝贝确实存在质量问题，请在收到后48小时内及时联系我们，我们一定会为您妥善解决，并请保证宝贝没有人为损坏、洗涤及包装吊牌无损等，由此产生的邮费由我们承担。</span>
      </p>
      <p><strong> <span>关于退换货问题</span> </strong></p>
      <p style="text-indent: 2em;line-height: 27px;">
        <span>确属质量问题的商品，须及时寄给我们，我们收到后查核属实将给予掉换。退换的商品，不可洗涤，不可人为损坏。必须保持包装及吊牌/领标等原配件完整，原封退回。不接受任何形式的到付！不接受未协商好自行申请退款！海外直购产品一般情况不予退换货！</span>
      </p>
      <p><strong> <span>补充说明：</span> </strong></p>
      <p style="text-indent: 2em;line-height: 27px;">
        <span>同商品可能涉及到不同的仓库，因发货仓库不同，货物配送到您手中的时效会稍有区别，请以实际为准；海外直购产品请您确保订单中的收货地址、收件人姓名、收件人身份证号码、手机号码等各项信息的准确性和有效性，以便商品及时、准确地发出，并随时查看个人账户里“我的订单”，关注订单状态更新情况；如因收货人个人原因导致货物退回，由此产生的费用或其他后果由客户自行承担。</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'purchaseNotesZj'
}
</script>

<style scoped>

</style>

<template>
  <div>
    <div v-if="'hetun' === heTunInfo"><purchaseNotesZj></purchaseNotesZj></div>
    <div v-else>
      <div class="title">购买须知</div>
      <div style="text-align: center; line-height: 20px; margin-top: 10px;">
        发布时间：2022-01-07 17:54:39
      </div>
      <div data-v-0ab10f90="" style="font-size: 14px;"><p>&nbsp;</p>
        <p>a）什么是保税区？</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;保税区是海关设置的或经海关批准注册、受海关监督和管理的可以较长时间存储商品的区域。（保税区能便利转口贸易，增加有关费用的收入。&nbsp;外国商品存入保税区，不必缴纳进口关税，尚可自由出口，只需交纳存储费和少量费用，但如果要进入关境则需交纳关税。）</p>
        <p>b）{{ $tp().OPEN_COM_NAME }}的商品都是正品吗？</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;平台上所有产品都是供应商海外原装进口，海关监管并检验，保税区直接发货到用户手上，并且每个商品进驻海关保税区，都需要做商检，以确保商品是正品。</p>
        <p>c）保税订单什么时候发货，多久到货？</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;一般正常情况下，客户会在下单后3-7个工作日左右收到货，我们推单的时候会综合考虑保税仓的库存、发货速度以及价格等因素，优先推送合适的保税区。正常情况下运单号会在48小时之内出，随后按照正常的物流运转时间到客户手上。</p>
        <p>&nbsp;</p>
        <p>d）是否支持退货？</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;由于跨境购物自身的特殊性（物流距离，耗时较长，商品退换货出关时存在税费及清关障碍等问题），所售商品不支持任何无理由退换货，不支持无理由拒收。此类商品如果被拒收或退回，将扣除订单总金额30%的手续费。请您务必当场验收，有问题请拍照拒收并在售后规定时间内联系客服处理，没问题再签收！（其他售后问题请参考帮助中心的售后服务）。</p>
        <p>补充说明：</p>
        <p>1&nbsp;）根据财政部，海关总署，国家税务总局规定，参照中华人民共和国财政部关税【2016】18号《关于跨境电子商务零售进口税收政策的通知》要求，跨境电&nbsp;子商务零售进口商品的单次交易限值为人民币5000元，个人年度交易限值为人民币26000元。在限值以内进口的跨境电子商务零售进口商品，进口环节增值&nbsp;税、消费税取消免征税额，暂按法定应纳税额的70%征收。对于违反上述规定的系统将给予退单；</p>
        <p>2&nbsp;）同商品可能涉及到不同的仓库，因发货仓库不同，货物配送到您手中的时效会稍有区别，请以实际为准；</p>
        <p>3&nbsp;）请您确保订单中的收货地址、收件人姓名、收件人身份证号码、手机号码等各项信息的准确性和有效性，以便商品及时、准确地发出，并随时查看个人账户里“我的订单”，关注订单状态更新情况；</p>
        <p>4&nbsp;）如因收货人个人原因导致货物退回，由此产生的费用或其他后果由客户自行承担。</p>
      </div>
    </div>
  </div>
</template>

<script>
import purchaseNotesZj from '@/views/Help/components/purchaseNotesZj'

/**
 * @author  XuHongli
 * @date  2022/11/15 10:29
 * @version 1.0
 * @description
 */
export default {
  name: 'PurchaseNotes',
  components: { purchaseNotesZj },
  computed: {
    heTunInfo() {
      if (process.env.NODE_ENV === 'production') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'pre') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'hetun') {
        return process.env.NODE_ENV
      } else {
        return process.env.NODE_ENV
      }
    }
  }
}
</script>

<style scoped>

</style>
